a {
  text-decoration: none;
  color: white;
}

a.rbc-show-more {
  color: black !important
}

.App {
  text-align: center;
  
}
.xmas{
  background-color: #0F8A5F;
 
}

.xmas .menu-div {
  color:white;
  background-color: #34A65F;
}

.xmas .menu-div span{
  color:white;
}
 
.xmas main div {
 
  
 /*  background-color: white; */
}  

.xmas header{
   background-color: #CC231E;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.card-wrapper {
  position: relative;
}

.card {
  border: 3px solid transparent;
  border-radius: 5px;
  overflow: hidden;
}

.card-dragged {
  position: absolute;
  transform-origin: bottom left;
  -webkit-backface-visibility: hidden;
}

.card-wrapper-active:not(.card-wrapper-dragging) .card {
  border: 3px solid #4e56ff;
}

.card-outer {
  border: 2px solid transparent;
  border-radius: 2px;
  overflow: hidden;
}

.card-wrapper-selected:not(.card-wrapper-dragging) .card-outer {
  border: 4px solid  #0086ff;
}

.card-inner {
  position: relative;
  background-color: rgba(255, 255, 255, 0.05);
  color: #aaa;
  display: flex;
  max-width: 25em;
  text-align: center;
  justify-content: center;
  flex-direction: column;

}

.card-dragged .card-inner {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.35);
}

.card-wrapper-dragging .card-inner {
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.card-wrapper-dragging.card-wrapper-hovered .card-inner {
  border: 4px solid #0086ff;
  border-radius: 2px;
  background-color: transparent;
}

.card-wrapper-dragging .card-inner img {
  opacity: 0;
}

.insert-line-left,
.insert-line-right {
  position: absolute;
  top: 0;
  left: -1px;
  height: 100%;
  width: 4px;
  background-color: #0086ff;
}

.insert-line-right {
  left: auto;
  right: -1px;
}