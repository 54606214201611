* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Mobile Styles */
@media only screen and (max-width: 400px) {
  body {}

  td.listing-list-cell {
    padding-right: 10px;
    padding: 4px 4px 4px 4px;
    white-space: nowrap;

  }

  td.listing-list-cell:last-child {
    padding-right: 4px;
  }

  th.listing-list-cell {
    padding-right: 10px;
    padding: 4px 4px 4px 4px;
  }

  th.listing-list-cell:last-child {
    padding-right: 4px;
  }

  .hmdUserList {
    height: 175px;
    overflow-y: scroll;
  }

  .button-operations {

    align-items: flex-start;

  }

  .button-operations button {
    float: left;
    padding: 0px;
    width: 40%;
    font-size: 0.5em;
  }

  .button-operations label {
    float: left;
    padding: 0px;
    width: 40%;
    font-size: 0.5em;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .shooting-planner {}


}

/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {
  body {}

  .hmdUserList {
    height: 200px;
    overflow-y: scroll;
  }



}

/* Desktop Styles */
@media only screen and (min-width: 961px) {
  body {}

  .hmdUserList {
    height: 200px;
    overflow-y: scroll;
  }

  p div .mobile-select {
    /* display: none; */

  }

  div .mobile-select {
    /* display: none; */

  }

  #mobil-unassign {
    /* display: none; */
  }

  .mobile-select {
    /* display: none; */
  }

  .unass {
    display: none;

  }

  td.listing-list-cell {
    padding-right: 10px;
    padding: 4px 4px 4px 4px;
    white-space: nowrap;

  }
}

.no-bullet {

  list-style-type: none;
}


.greyGrad {
  background: linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 37%, rgba(250, 250, 250, 1) 95%, rgba(250, 250, 250, 1) 100%);
  /*linear-gradient(to top, #edf4ff, #eef1fa, #eeeff5, #ededf0, #ebebeb);*/
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 37%, rgba(255, 255, 255, 1) 95%, rgba(209, 209, 209, 1) 100%);
}

.center-text {
  text-align: center !important;
}