body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Mobile Styles */
@media only screen and (max-width: 400px) {
  body {}

  td.listing-list-cell {
    padding-right: 10px;
    padding: 4px 4px 4px 4px;
    white-space: nowrap;

  }

  td.listing-list-cell:last-child {
    padding-right: 4px;
  }

  th.listing-list-cell {
    padding-right: 10px;
    padding: 4px 4px 4px 4px;
  }

  th.listing-list-cell:last-child {
    padding-right: 4px;
  }

  .hmdUserList {
    height: 175px;
    overflow-y: scroll;
  }

  .button-operations {

    align-items: flex-start;

  }

  .button-operations button {
    float: left;
    padding: 0px;
    width: 40%;
    font-size: 0.5em;
  }

  .button-operations label {
    float: left;
    padding: 0px;
    width: 40%;
    font-size: 0.5em;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .shooting-planner {}


}

/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {
  body {}

  .hmdUserList {
    height: 200px;
    overflow-y: scroll;
  }



}

/* Desktop Styles */
@media only screen and (min-width: 961px) {
  body {}

  .hmdUserList {
    height: 200px;
    overflow-y: scroll;
  }

  p div .mobile-select {
    /* display: none; */

  }

  div .mobile-select {
    /* display: none; */

  }

  #mobil-unassign {
    /* display: none; */
  }

  .mobile-select {
    /* display: none; */
  }

  .unass {
    display: none;

  }

  td.listing-list-cell {
    padding-right: 10px;
    padding: 4px 4px 4px 4px;
    white-space: nowrap;

  }
}

.no-bullet {

  list-style-type: none;
}


.greyGrad {
  background: linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 37%, rgba(250, 250, 250, 1) 95%, rgba(250, 250, 250, 1) 100%);
  /*linear-gradient(to top, #edf4ff, #eef1fa, #eeeff5, #ededf0, #ebebeb);*/
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 37%, rgba(255, 255, 255, 1) 95%, rgba(209, 209, 209, 1) 100%);
}

.center-text {
  text-align: center !important;
}
a {
  text-decoration: none;
  color: white;
}

a.rbc-show-more {
  color: black !important
}

.App {
  text-align: center;
  
}
.xmas{
  background-color: #0F8A5F;
 
}

.xmas .menu-div {
  color:white;
  background-color: #34A65F;
}

.xmas .menu-div span{
  color:white;
}
 
.xmas main div {
 
  
 /*  background-color: white; */
}  

.xmas header{
   background-color: #CC231E;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.card-wrapper {
  position: relative;
}

.card {
  border: 3px solid transparent;
  border-radius: 5px;
  overflow: hidden;
}

.card-dragged {
  position: absolute;
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  -webkit-backface-visibility: hidden;
}

.card-wrapper-active:not(.card-wrapper-dragging) .card {
  border: 3px solid #4e56ff;
}

.card-outer {
  border: 2px solid transparent;
  border-radius: 2px;
  overflow: hidden;
}

.card-wrapper-selected:not(.card-wrapper-dragging) .card-outer {
  border: 4px solid  #0086ff;
}

.card-inner {
  position: relative;
  background-color: rgba(255, 255, 255, 0.05);
  color: #aaa;
  display: flex;
  max-width: 25em;
  text-align: center;
  justify-content: center;
  flex-direction: column;

}

.card-dragged .card-inner {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.35);
}

.card-wrapper-dragging .card-inner {
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.card-wrapper-dragging.card-wrapper-hovered .card-inner {
  border: 4px solid #0086ff;
  border-radius: 2px;
  background-color: transparent;
}

.card-wrapper-dragging .card-inner img {
  opacity: 0;
}

.insert-line-left,
.insert-line-right {
  position: absolute;
  top: 0;
  left: -1px;
  height: 100%;
  width: 4px;
  background-color: #0086ff;
}

.insert-line-right {
  left: auto;
  right: -1px;
}
